import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

const Side = ({allFeature}) =>
  <div>

    <div className="row mb2">
      <div className="col-xs-0 col-sm-12 col-md-12 col-lg-12">
        <Link className="link near-black hover-mid-gray" to={'/discover'}>Overview</Link>
      </div>
    </div>

    <div>
      {
        allFeature.edges
        .filter(e => e.node.quid != undefined)
        .map(({node: {path, headline: { icon: { publicURL } }, quid: { what: { topic }}}}, idx) => {

          return (
            <div key={idx}>
              <Link className="near-black hover-mid-gray" to={`${path}#index`}>
                <div className="pt2 flex items-center">
                  <img className="w5-3 h5-3 mb0" src={publicURL}/>
                  <span className="ml3 f5">{topic}</span>
                </div>

              </Link>
            </div>
          );
        })
      }
    </div>
  </div>

export const Sidebar = () =>
  <StaticQuery query={query}>
    {(props) => <Side {...props}/>}
  </StaticQuery>

const query = graphql`
  query {
    allFeature(sort: {fields: importance, order: DESC}) {
      edges {
        node {
          ...featureFields
        }
      }
    }
  }
`
;