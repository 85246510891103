import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql }  from 'gatsby';

import Layout from '../components/layout';

import { DiscoverBody } from '../components/blocks/DiscoverBody';

const ProductTemplate = ({data: { feature : { flows, quid }}}) =>
  <Layout>
    <DiscoverBody>
      <div className="mt4 mt0-ns" id="index">
        <h3>{quid.what.topic}</h3>
        {quid &&
          <div>
            <div>
              <p>{quid.what.description}</p>
            </div>

            <div className="mt3">
              <h4>Benefit</h4>
              <p>{quid.why}</p>
            </div>

            {
              flows && flows.map(({ diagramURL, description, facets }, idx) => {
                return (
                  <div key={idx} className="mt4">
                    <h4>Option: {description}</h4>
                    <div className="pl2 pr2 mt4 mb4 center">
                      <img src={diagramURL} />
                    </div>
                    <div className="">
                      <ol>
                       { facets.map((facet, idx) => <li key={idx}>{facet}</li>) }
                      </ol>
                    </div>
                  </div>
                );
              })
            }

            <div className="mt3">
              <h4>How To Use It</h4>
              <ul>
                {quid.how.map((item, idx) => (<li key={idx}>{item}</li>))}
              </ul>
            </div>
          </div>
        }
      </div>
    </DiscoverBody>
  </Layout>

export default ProductTemplate;

export const query = graphql`
  query ProductFeature($id: String!) {
    feature(id: { eq: $id } ) {
      ...featureFields
    }
  }
`
;