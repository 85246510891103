import React from 'react';

import { Sidebar } from './Sidebar';
import { GridRow } from '../widgets/grid';
import { PageTitle } from '../title';

//import StickyBox from 'react-sticky-box';

export const DiscoverBody = ({ children }) =>
  <div className="mb4">

    <PageTitle title='Discover' />

    <GridRow>

      <div className="row">
        <div className="col-xs-11 col-sm-3 col-md-3 col-lg-3">
          {/* <StickyBox offsetTop={20} offsetBottom={20}> */}
            <Sidebar />
          {/* </StickyBox> */}
        </div>
        <div className="col-xs-11 col-sm-9 col-md-9 col-lg-9">
          {children}
        </div>
      </div>

    </GridRow>
  </div>